@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap);
.App {
  text-align: center;
   height: 100%; 
  display: -webkit-flex; 
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.mainCon {
  display: -webkit-flex;
  display: flex;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

