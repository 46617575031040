.App {
  text-align: center;
   height: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mainCon {
  display: flex;
}
